import React, { useState,useEffect } from "react";
import { UploadOutlined,MinusOutlined ,CloudUploadOutlined ,FilePdfOutlined} from "@ant-design/icons";
import axios from "axios";
import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import type { GetProp, UploadProps } from 'antd';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];


const getBase64 = (img: FileType, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const beforeUpload = (file: FileType) => {
  const isJpgOrPng = file.type === 'application/pdf';
  console.log(file.name);
  if (!isJpgOrPng) {
    message.error('You can only upload PDF file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('PDF must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};
type ChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => void;

interface Props {
  handleChange: (name: string, event: any) => void;
}

const MyPDFUploader: React.FC<Props> = ({handleChange}) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [filename, setFilename] = useState<string>();

  const handleUploadChange: UploadProps['onChange'] = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      
      // Get this url from response in real world.

      getBase64(info.file.originFileObj as FileType, (url) => {
        setLoading(false);
        setImageUrl(url);
        const uploadedFile = (info.file.originFileObj || info.file) as File;
        handleSubmit2(uploadedFile);
        console.log(url);
        setFilename(info.file.name);

      });
    }
  };


  const handleSubmit2 = async (file:File) => {
    try {
      console.log("asd");
      if (file) {
      const formData = new FormData();
      // formData.append('csrfmiddlewaretoken', csrfToken);
      formData.append("pdf_file", file);
      console.log(file);
        //console.log(csrfToken);
        const response = await fetch(
          //"http://localhost:8000/api/post-csrf-token/",
          "https://ramiz-android.de.r.appspot.com/api/extract_sections/",
          {
            method: "POST",
            credentials: "include",
            body:formData,
            headers: {
              // ... your headers
              //"Content-type":"multipart/form-data",
              "Authorization": 'Bearer xrxr',
              //"X-CSRFToken":'5rdnIGg0T32nHbaWEdL6EgapyJUxO4hW4mdcU4Sx0dm4EYU5IpklqBGPkyw4SOHV',
              //  'Cookie': document.cookie,

            },
          
          }
        );
       

        const data = await response.json();
        console.log(data);
        handleChange("pdf_tests",data)
        // ... handle the response data
        }
        else
        {
          console.log("sdfs");
        }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // Set loading to false once the data is fetched
    }
  };
  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  // Function to determine the icon based on file type
  // const getFileIcon = () => {
  //   if (file && file.type === "application/pdf") {
  //     return <FilePdfOutlined />;
  //   } else {
  //     return null;
  //   }
  // };

  return (
    
    <>
    <Upload
      name="avatar"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
      beforeUpload={beforeUpload}
      onChange={handleUploadChange}
    >
      
      

      { imageUrl ? <span style={{width:"100%"}}><FilePdfOutlined  style={{fontSize: '40px',color:"#eb2f96"}}/></span>: uploadButton}
      
    </Upload>
    <span>
    {filename}
    </span>
    
    </>
 
   
  );
};

export default MyPDFUploader;
