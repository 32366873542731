import React, { useState, useEffect } from "react";
import { Card, Descriptions } from "antd";
import { Grid } from "@mui/material";
import { GenderDetection_En } from "../utils/bases/functions";
import { ClockCircleOutlined } from "@ant-design/icons";

function MedicalPreConfigViewer({ aiQueryData }) {
  const [citydata, setCity] = useState();
  const [provincedata, setProvince] = useState();
  console.log(aiQueryData[0].Test_Types.includes("B"));

  useEffect(() => {
    // console.log(aiQueryData.Test_Types);
  }, []);

  return (
    <div dir="ltr">
      <div className="container">
        {aiQueryData
          ? aiQueryData.map((data) => (
              <Card key={data.id} style={{ width: "80%" }}>
                <Descriptions bordered size="small">
                  <Descriptions.Item label="Name & family Name">
                    <div>
                      <div className="row">
                        <div className="col-sm-8"> {data.name}</div>
                      </div>
                    </div>
                  </Descriptions.Item>
                  <Descriptions.Item label="Gender">
                    <div>
                      <div className="row">
                        <div className="col-sm-8">
                          {" "}
                          {GenderDetection_En(data.gender)}
                        </div>
                      </div>
                    </div>
                  </Descriptions.Item>
                  <Descriptions.Item label="Age">
                    <div>
                      <div className="row">
                        <div className="col-sm-8"> {data.age}</div>
                      </div>
                    </div>
                  </Descriptions.Item>
                  {aiQueryData[0].Test_Types.includes("B") ? (
                  <Descriptions.Item label="Attached File">
                    <div>
                      <div className="row">
                        <div className="col-sm-8"> "Files Attached"</div>
                      </div>
                    </div>
                  </Descriptions.Item>
                  
                )  : ("")
              }
                </Descriptions>

                <Grid spacing={2}>
                  <Grid item xs={12} sm={12}>
                   
                    {aiQueryData[0].Test_Types.includes("A") ? (
                      <Card
                        title="Drugs :"
                        type="inner"
                        style={{
                          marginTop: 5,
                          width: "100%",
                          justifyContent: "center",
                        }}
                      >
                        <>
                          {data.drugs.map((item, index) => (
                            <p key={index}>
                              <Descriptions bordered size="small">
                                <Descriptions.Item label="Drugs Name ">
                                  <div>
                                    <div className="row">
                                      <div className="col-sm-1">
                                        {"fgdfg "}
                                        {item.drug}
                                      </div>
                                    </div>
                                  </div>
                                </Descriptions.Item>
                                <Descriptions.Item label="Dos ">
                                  <div>
                                    <div className="row">
                                      <div className="col-sm-1">
                                        {" "}
                                        {item.drugdoze}
                                      </div>
                                    </div>
                                  </div>
                                </Descriptions.Item>
                                <Descriptions.Item label="Duration">
                                  <div>
                                    <div className="row">
                                      <div className="col-sm-1">
                                        {" "}
                                        {item.TimeDuration}
                                      </div>
                                      <ClockCircleOutlined />
                                    </div>
                                  </div>
                                </Descriptions.Item>
                                <Descriptions.Item label="Count">
                                  <div>
                                    <div className="row">
                                      <div className="col-sm-3">
                                        {" "}
                                        {item.count}
                                      </div>
                                    </div>
                                  </div>
                                </Descriptions.Item>
                              </Descriptions>
                            </p>
                          ))}
                        </>
                      </Card>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Card>
            ))
          : ""}
      </div>
    </div>
  );
}

export default MedicalPreConfigViewer;


